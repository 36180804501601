import { addAlpha, colors } from 'fitify-ui/src/theme/colors'
import { styled, css } from 'styled-components'

import { Theme } from '../../types/theme'
import { BREAKPOINTS } from '../../utils/styled'
import { Stack } from '../Stack'

export const StyledThresholdWrapperV2 = styled(Stack)`
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: var(--s-continue-button);
  z-index: var(--z-index-threshold-button);
  pointer-events: none;

  @media (max-height: 400px) {
    margin-top: 2rem;
    position: relative;
  }

  button {
    pointer-events: auto;
  }
`

export const StyledThresholdWrapper = styled(Stack)<{
  containerHeight: number
}>`
  justify-content: end;
  align-items: center;
  min-height: ${({ containerHeight }) => containerHeight}px;
  margin-top: 2rem;

  position: sticky;
  bottom: 0;
  padding-top: 1.5rem;
  padding-bottom: var(--s-continue-button);
  max-width: var(--w-content);
  z-index: var(--z-index-threshold-button);

  background: linear-gradient(
    to top,
    var(--c-blue850) 50%,
    rgba(12, 61, 125, 0) 100%
  );
`

const getSurfaceColor = (theme: Theme) => {
  switch (true) {
    case theme.hcV1:
      return colors.Gray100
    default:
      return colors.White
  }
}

export const StyledThresholdBackground = styled.div`
  ${(props) => {
    const themeSurface = getSurfaceColor(props.theme)
    const alphaColor = addAlpha(themeSurface, 0.9)
    const transparent = addAlpha('#ffffff', 0)

    return css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: calc(100% + var(--s-continue-button));
      z-index: -1;

      background: linear-gradient(180deg, ${transparent} 0%, ${alphaColor} 35%);

      @media ${BREAKPOINTS.MD.lte} {
        width: 100%;
      }
    `
  }}
`

export const StyledCoachContainer = styled(Stack)`
  height: 100%;
`
