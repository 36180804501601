import { useDeviceSize } from 'fitify-ui/src/hooks/useDeviceSize'
import { useEffect, useRef, useState } from 'react'

import { StyledThresholdWrapper } from './Threshold.Styled'

import { ThresholdProps } from '.'

const ThresholdWrapper = ({ children }: ThresholdProps) => {
  const { windowWidth } = useDeviceSize()
  const [containerHeight, setContainerHeight] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const childRefs = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    const heights = childRefs.current.map((ref) => ref?.offsetHeight || 0)
    const sumOfHeights = heights.reduce((acc, height) => acc + height, 0)

    setContainerHeight(sumOfHeights)
  }, [childRefs, containerRef, windowWidth])

  return (
    <StyledThresholdWrapper
      ref={containerRef}
      containerHeight={containerHeight}
    >
      {children}
    </StyledThresholdWrapper>
  )
}

export default ThresholdWrapper
